<script>
import store from '@/store'
import QRReader from '@/components/QRReader'
import { SideBtnType } from '@/store'

export default {
    components: { QRReader },
    props: {
        box: {
            type: String,
            default: ''
        },
        door: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            titles: [],
            preStepNo: 0,
            stepNo: 1,
            doorId: 0,
            camera: 'off',
            isFromLinePay: false
        }
    },
    watch: {
        boxId() {
            if (this.isFromLinePay) return;
            if (this.boxId) this.setStepNo(2)
        }
    },
    methods: {
        init() {
            store.commit('initTransaction')
            store.commit('setTargetBoxId', '')
            store.commit('setMQTTPayload', null)
            this.boxId = ''
            this.preStepNo = 0
            this.stepNo = 1
            store.commit('setTitle', this.titles[this.stepNo - 1])
            this.doorId = 0
            this.isFromLinePay = store.commit('setIsFromLinePay', false)
            this.isFromLinePay = false
        },
        setStepNo(no) {
            if (no === 1) {
                this.init()
                this.camera = 'auto'
            } else this.camera = 'off'
            this.preStepNo = this.stepNo
            this.stepNo = no
            store.commit('setTitle', this.titles[this.stepNo - 1])
        },
        nextStep() {
            this.setStepNo(Math.min(this.stepNo + 1, this.titles.length))
        },
        preStep() {
            this.setStepNo(Math.max(this.stepNo - 1, 1))
        },
        onQuery(query) {
            store.commit('setTargetBoxId', query.box_id)
            this.boxId = query.box_id
            this.doorId = query.door_id
        }
    },
    activated() {
        store.commit('setSideBtnType', SideBtnType.Back)
        this.init()
        if (this.box && this.door > 0) this.onQuery({box_id: this.box, door_id: this.door})
        else this.camera = 'auto'
    },
    deactivated() {
        this.camera = 'off'
        store.commit('setSideBtnType', SideBtnType.Navi)
    }
}
</script>
